<template>
    <div class="home">
        <!-- 头部 -->
        <myHead></myHead>

        <div class="helpConten">
            <div class="l">
                <div class="son" v-for="(i, index) in menuArr" :key="index" :class="[i.act ? 'actShow' : '']"
                    @click="menuClick(index)" :style="{ fontWeight: index < 6 && 1 < index ? '400' : '' }">{{ i.name }}
                </div>
            </div>
            <div class="r" v-show="indexAct == 0">
                <div class="title">
                    1.账号注册
                </div>
                <div><span class="red">账号注册：</span> 下载商城app，进入登陆页面，填入手机号码和短信验证码,并同意《平台隐私政策》及《用户协议》即可注册成功</div>
            </div>
            <div class="r" v-show="indexAct == 1 || indexAct == 2">
                <div class="title">
                    1.认证商户类型
                </div>
                <div><span class="red">普通店：</span> 个人用户可申请开通的常规型店铺；</div>
                <div><span class="red">专卖店：</span> 须拥有品牌授权证书，成为品牌的代理店铺；</div>
                <div><span class="red">旗舰店：</span> 品牌自营的官方店铺；</div>

                <div style="margin-top: 30px;"><span class="red">*所有类型店铺均需要平台审核无误后才能开通。</span> </div>
            </div>
            <div class="r" v-show="indexAct == 3">
                <div class="title">
                    1.如何成为官方代理
                </div>
                <div><span class="red">如何成为官方代理：</span> 成为官方代理需提前成为平台商户用户，然后通过APP首页的【代理申请】入口提交申，填写申请理由后等待平台审核结果即可。</div>
            </div>
            <div class="r" v-show="indexAct == 4">
                <div class="title">
                    1.提交申请后多久通过
                </div>
                <div><span class="red">提交申请后多久通过：</span> 一般在提交申请后48小时内（非工作日除外）会告知审核结结果。</div>
            </div>
            <div class="r" v-show="indexAct == 5">
                <div class="title">
                    1.如何变更商铺名
                </div>
                <div><span class="red">如何变更商铺名：</span>目前暂不支持用户修改商铺名，如有特殊情况，可联系平台客服。</div>
            </div>
            <div class="r" v-show="indexAct == 6">
                <div class="title">
                    1.选型方式
                </div>
                <div><span class="red">方法一：</span>在顶部搜索框直接输入产品型号或产品名称，搜索对应产品；</div>
                <div><span class="red">方法二：</span>直接通过产品分类来进行类目来筛选对应产品；</div>
            </div>
            <div class="r" v-show="indexAct == 7">
                <div class="title">
                    1.选择商品的类型：
                </div>
                <div><span class="red">1.选择商品的类型：</span>选择商品的对应规格型号及其它参数后，点击加入购物车</div>
                <div><span class="red">2.生成订单：</span>在“购物车”确认所需物料的数量、价格、规格型号等参数确认无误后，点击【立即购买】可直接生成订单。</div>
                <div><span class="red">3.完成支付：</span>【点击购买】后生成的订单，选择【收货地址】信息，在下方选择支付方式【支付宝支付】或【微信支付】后付款即可。</div>
                <div><span
                        class="red">4.如何追踪自己订单：</span>【在APP底部导航选择【我的】进入对应页面，在页面功能模块找到【我的订单】，即能查看自己在平台上对应状态的订单信息（如，须付款，待发货，待评价及退款售后）
                </div>
            </div>
            <div class="r" v-show="indexAct == 8">
                <div class="title">
                    1.注册
                </div>
                <div><span class="red">如何注册：</span>下载商城app，进入登陆页面，填入手机号码和短信验证码,并同意《平台隐私政策》及《用户协议》即可注册成功；</div><br>
                <div><span class="red">个人用户能申请成为代理吗？</span>目前平台不支持个人用户成为代理商。需优先成为平台商户用户才可进行代理申请。</div><br>
                <div><span class="red">手机收不到验证码怎么办？：</span></div><br>
                <div>手机点击“获得验证码”按钮后，若收不到验证码，30秒后可再次点击“获得验证码”。如果还是收不到手机验证码，可能是由于以下原因：</div>
                <div><span class="red">1）手机通讯服务商网关异常。</span>解决方法：换时间段再申请。</div>
                <div><span class="red">2）节假日手机通讯服务商短信发送拥堵</span>解决方法：换时间段再申请。</div>
                <div><span class="red">3）不支持的手机号段。</span>解决方法：目前手机验证支持中国大陆地区号码；暂不支持小灵通、港澳台、海外手机号码。请更换大陆手机号码注册。</div>
                <div><span class="red">4）同一时间段多次注册，多次获取验证码。</span>解决方法：换时间段再申请；或联系商城客服解决。</div><br>
                <div><span class="red">个人用户如何成为商家用户？</span>在APP首页点击【商家入驻】，并进行实名认证和商户基本信息填写完成后提交至平台进行审核，审核通过即可成为商家用户。</div>
                <br>
                <div><span class="red">个人用户能申请成为代理吗？</span>目前平台不支持个人用户成为代理商。需优先成为平台商户用户才可进行代理申请。</div><br>
                <div><span class="red">如何修改个人资料</span>成功注册成为平台用户并登陆完成，在底部导航选择【我的】进入对应页面，点击页面上的头像即可进入个人资料修改页面进行个人信息资料修改。
                </div><br>
                <div class="title">
                    2.登陆
                </div>
                <div><span class="red">登录失败怎么办？：</span>如果提示该手机号没有注册帐号或还未绑定帐号，请检查输入是否正确，或使用该手机号码注册新账号</div><br>
                <div class="title">
                    3.商户用户
                </div>
                <div><span class="red"></span>成功成为平台商户后，会有短信提示对应的登陆链接及初始登陆密码。</div>
                <div><span class="red"></span>商户后台登陆入口：https://store.cnc-x.cn</div><br>
                <div><span class="red">如何修改商户后台密码？</span>从商户后台成功登陆后，点击页面右上角头像，选择下拉菜单中【修改】，在弹窗内输入需要修改及确认新密码后，点击【立即修改】即可。
                </div><br>
                <div><span class="red">为什么成为商户要企业资质证书？</span>为了保证网站客户质量，避免虚假信息与恶性竞争，平台要求注册企业均进行实名认证并提供相关营业执照证明店铺所上商品的真实性。
                </div><br>
                <div><span class="red">关于商户用户:</span>商户用户拥户自己的店铺，并享受平台商品交易的利润及相对应于的服务。因此，商户用户须严格把控自己店铺所售商品的真实性，不得以次充好等应尽的义务。
                </div><br>

            </div>
            <div class="r" v-show="indexAct == 9">
                <div class="title">
                    1.收藏
                </div>
                <div><span class="red">我的收藏的作用是什么?</span>【我的收准】是为了用户在平台浏览时对相对应的产品便干以后快速跳转及购买的功能。</div>
                <div><span class="red">商品购买的时候是否可以指定物流?</span>成功登陆APP后，点击底部导航【我的】进入对页面，在功能模块入口找到【我的收藏】进行对应相关的操作，如(删除等)</div><br>
                <div class="title">
                    2.物流
                </div>
                <div><span
                        class="red">如何杳看对应订单的物流信息?</span>成功登陆后在底部功能菜单【我的】页面，点击【我的订单】进入订单列表页面，选中对应的订单(如待收货)进入订单详情，详情顶部即可显示订单当前物流运输状态。
                </div>
                <div><span
                        class="red">下单后发现收货地址错误怎么办?</span>如商家对该笔订单还来讲行发货外理，可在订单详情的底部按讯【修改地址】可修改收货地址:如果商家已进行发货，需联系卖家商户进行沟通协商，平台将不用户支持自主修改。
                </div>
                <div><span class="red">商家迟迟不发货可以申请退款吗?</span>如商家一直未发货并沟诵无果的前提下，可由请退款外理，如商家拒绝退款且还未发货，可联系平台客服介入。</div>
                <div><span class="red">商品购买的时候是否可以指定物流?</span>物流运输一般是由卖家选择，平台不支持自选物流。如有需要，请与卖家协商沟通。</div><br>
                <div class="title">
                    3.寻源
                </div>
                <div><span
                        class="red">寻源是什么？</span>寻源工具是平台为工业品买家提供的全新服务，在买家提交寻源需求后，系统会自动为你匹配优质的供应商进行选品报价，然后买家可在商家报价后进行比价洽谈下单。
                </div>
                <div><span class="red">如何发布寻源？</span>成功登陆后在底部导航点击【寻源】跳转到对应页面，点击【立即发布】后填写需求信息（如名称，类目，数量等信息）后立即发布即可。</div>
                <div><span class="red">关于寻源报价（仅入驻商户）</span></div>
                <div><span class="red">方法一：</span>商家用户可通过APP内底部菜单【我的】点击【公开询盘】进入，可通过搜索及筛选来查看平台已公开的寻源列表进行【查看洋情】和【报价】处理。</div>
                <div><span class="red">方法二：</span>商家用户可以进入商户后台，点击侧边导航【店铺数据——询单管理——公开询盘】查看，通过搜索及筛选来挑选寻单进行【报价】处理。</div>
                <div><span class="red">如何查看我的寻源发布？</span>用户通过APP底部菜单【我的】进入点击【我的寻源】查看自己发布的寻源列表及对应寻源的供应商报价信息等。</div><br>
                <div class="title">
                    4.订单
                </div>
                <div><span class="red">订单明细状态解释</span></div>
                <div><span class="red">【待支付】您提交的订单，未选择支付方式 或者 选择支付宝或微信支付，但未完成支付，明细项状态显示:</span>待支付</div>
                <div><span class="red">【待发货】您提交的订单，商家还未进行物流运输，明细项状态显示：</span>待发货</div>
                <div><span class="red">【已发货】您提交的订单，商家已安排物流运输，明细项状态显示：</span>已发货</div>
                <div><span class="red">【待收货】您提交的订单，物流已确认，正在准备远输，明细项状态显示：</span>待收货</div>
                <div><span class="red">【已完成】您提交的订单，在您确认收货后，明细项状态显示：</span>已完成</div>
                <div><span class="red">【已终止】您提交的订单，超过30分钟后未选择付款方式 或 <br>选择支付宝/微信支付但未完成付款，订单将自动终止，明细项状态显示：</span>交易关闭</div><br>

                <div><span class="red">如何下单：</span>在商城内搜索对应的商品——选择商品的规格及其它参数——添加至购物车——点击【立即购买】生成订单，然后选择付款方式完成付款。</div><br>
                <div><span class="red">怎么取消订单：</span>成功对指定商品完成下单后可以在【我的订单】里查看【未付款】列表对指定订单点击【取消订单】直接取消。</div><br>
                <div><span class="red">添加收货地址：</span>在登陆APP后，进入【我的】页面，找到【地址管理】，点击【添加地址】按钮，填写相关收件信息并保存即可。</div><br>
                <div><span class="red">如何设置默认地址：</span>在APP内通过【我的】页面进入【地址管理】，点击相对应的地址进入对应编辑页，将【默认地址】开关进行开启，然后点击【确定】就可以将当前地址设置为默认收货地址。</div><br>
                <div><span class="red">如何修改订单地址：</span>在APP内通过【我的】页面进入【我的订单】，选择需要修改地址的订单进入“订单详情”，点击底部的【修改地址】进行收货地址的相关信息修改，然后点击【确定】。</div><br>
                <div><span class="red"></span>目前平台仅支持处理“待付款”“待发货”类型的订单进行收货地址的修改。</div><br>
                <div class="title">
                    5.支付
                </div>
                <div><span class="red">支付方式</span>目前平台仅支持【微信支付】及【支付宝支付】两种付款方式。</div>
                <div><span class="red">不同的支付方式，有效期是多久？</span>目前订单产生后，无论选择哪种付款方式，在30分钟内未成功支付，该笔订单都将会强制关闭。</div>
                <div><span class="red">如何取消支付？</span>如不小心下错商品规格订单，可在未付款时选择【取消订单】处理，然后重新下单。如已付款，请与商家协商后进行【申请售后】进行退款处理。</div>
                <div><span class="red">支付失败怎么办？如在支付时提示支付失败，解决方法如下：</span></div>
                <div><span class="red"></span>1）更换支付方法进行支付；</div>
                <div><span class="red"></span>2）将订单取消后，重新下单支付；</div>
                <div><span class="red"></span>*如支付仍提示失败，请联系平台客服进行处理。</div><br>
                <div class="title">
                    6.优惠
                </div>
                <div><span class="red">什么是优惠券</span>优惠券是平商户因为运营活动需要而设置的促销方式，活动规则是由商户自主决定。</div><br>
                <div><span class="red">优惠券类型</span></div>
                <div><span class="red">满减券：</span>购买商品满对应价格从而可以抵扣固定金额</div>
                <div><span class="red">折扣券：</span>按照一定折扣比例在付款时进行折扣</div>
                <div><span class="red">固定金额券：</span>可直接抵扣固定金额</div>
                <div><span class="red">卡密券：</span>需在使用前输入商家发放的卡密进行兑换</div><br>
                <div><span class="red">优惠券的商品限制</span></div>
                <div><span class="red"></span>购买店铺任意商品，只要满足使用条件就可享受对应优惠</div>
                <div><span class="red">指定商品：</span>购买指定商品且满足使用条件可享受对应优惠</div><br>
                <div><span class="red"></span>1）用户在登陆后进入首页点击【领券中心】进行优惠券的领取</div>
                <div><span class="red"></span>2）进入指定店铺，在商家配置的对应领券入口进行领券</div>
                <div><span class="red"></span>3）进入对应商品的详情页，点击【领券】按钮领取</div>
                <div><span class="red"></span>4）用户在APP登陆成功后点击底部菜单【我的】点击进入【我的优惠券】，在右上方点击【兑换】输入商家发放的卡密进行领券</div>
                <div><span class="red"></span>5）将商品添加至购物车后点击【去结算】，在订单页面选择【优惠券】后选择可使用的优惠券，然后选择付款方式进行成功支付即可。</div><br>

                <div><span class="red">为什么使用不了优惠券？</span>优惠券仅在其对应的店铺购买商品且需要满足对应条件（如金额需满足满减的标准）才能享受对应优惠。</div><br>
                <div class="title">
                    7.发货
                </div>
                <div><span class="red">发货方式</span>不同店铺的发货方式是商家自行安排。如有特殊需要，请与商家进行协商。</div>
                <div><span class="red">发货时间</span>订单的发货时间需根据商家的服务保障来确定，具体请在APP查看：我的——订单详情。</div>
                <div><span class="red">官方自营店铺</span>官方自营店铺商品均会在有库存的情况下48小时内进行发货，订制类商品将会与买家协商对应的商品交期进行发货，物流方式会实际情况进行安排。</div>

            </div>
            <div class="r" v-show="indexAct == 10">
                <div class="title">
                    1.退款
                </div>
                <div><span class="red">如何申请退款：</span>可在【我的订单】选择需退款的商品点击【申请售后——我要退款】填写对应的“退款原因”“退款金额”及“补充描述和凭证”后，点击【提交】，然后等待商家进行处理。</div><br>
                <div><span class="red">*用户在针对与该商品评价后是不可以申请退款。（确认收货后15天默认自动评价）</span></div>
                <div><span class="red">*为避免购物纠纷，请与商家协商沟通处理后申请。</span></div><br>
                <div class="title">
                    2.退换货
                </div>
                <div><span class="red">退换货地址：</span>申请退换货时需提前跟商系联系，将商品地址沟通确认。</div>
                <div><span class="red">如何申请退还/换货：</span>用户可在APP内点击【我的——我的订单——申请售后——退换货】进行申请，填写完毕提交后等待商家处理。</div>
                <div><span class="red">在哪查看我的售后订单：</span>用户可在APP内点击【我的——我的订单——退款/售后】进行查看过往的相关订单。</div>

            </div>
        </div>

        <!-- 底部 -->
        <bottomContent></bottomContent>
    </div>
</template>

<script>
import myHead from "@/components/myHead.vue";
import bottomContent from "@/components/bottomContent.vue";
export default {
    name: 'helpCenter',
    components: {
        myHead,
        bottomContent
    },
    data() {
        return {
            menuArr: [
                {
                    name: '账号注册',
                    act: true
                },
                {
                    name: '申请认证入驻平台',
                    act: false
                },
                {
                    name: '1.认证商户类型',
                    act: false
                },
                {
                    name: '2.如何成为官方代理',
                    act: false
                },
                {
                    name: '3.提交申请后多久通过',
                    act: false
                },
                {
                    name: '4.如何变更店铺名',
                    act: false
                },
                {
                    name: '产品选型',
                    act: false
                },
                {
                    name: '商品下单',
                    act: false
                },
                {
                    name: '账户使用',
                    act: false
                },
                {
                    name: '交易问题',
                    act: false
                },
                {
                    name: '售后问题',
                    act: false
                },
            ],
            // 当前激活index
            indexAct: 0
        }
    },
    mounted() {
        this.indexAct = this.$route.query.indexAct || 0
        this.menuClick(this.indexAct)
    },
    methods: {
        menuClick(index) {
            this.menuArr.map(t => t.act = false)
            this.menuArr[index].act = true
            this.indexAct = index
        }
    }
}
</script>

<style lang="less" scoped>
.home {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;

    >.helpConten {
        width: 1200px;
        height: 550px;
        display: flex;
        margin: 20px auto;

        >.l {
            width: 240px;
            height: 100%;
            background-color: #fff;
            margin-right: 16px;
            padding: 10px 20px;
            box-sizing: border-box;

            >.son {
                width: 100%;
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                color: #333;
                font-weight: bold;
                transition: all .3s;
                cursor: pointer;
            }

            >.son:hover {
                color: #E1241B;
                transition: all .3s;
            }
        }

        >.r {
            width: 940px;
            height: 100%;
            padding: 30px 20px;
            box-sizing: border-box;
            font-size: 14px;
            color: #333;
            background-color: #fff;
            overflow-y: auto;

            >.title {
                color: #000;
                font-size: 18px;
                margin-bottom: 30px;
            }

            >div {
                margin-top: 5px;
            }

            .red {
                color: #E1241B;
            }
        }
    }
}

.actShow {
    color: #E1241B !important;
    transition: all .3s;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
    width: 4px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: #d9d9d9;
}</style>